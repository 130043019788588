import { firebase } from "./firebase";
import { db } from "./db";
import { getLog } from "./log";
// eslint-disable-next-line no-unused-vars
let log = getLog("dbutils");

export async function setOrCreate(col, id, data) {
  if (id) {
    await col.doc(id).set(data);
    return await loadObj(col.doc(id));
  } else {
    let ref = await col.add(data);
    return await loadObj(ref);
  }
}

export async function deleteCollection(col) {
  const qs = await col.get();
  let batch = db.batch();
  var count = 0;
  qs.forEach((msg) => {
    if (count >= 500) {
      batch.commit();
      batch = db.batch();
      count = 0;
    }
    batch.delete(msg.ref);
    count += 1;
  });
  batch.commit();
  return qs.size;
}

export async function collectionFromIds(col, docIds) {
  if (docIds.length > 10)
    log.error("Only supports 10 users in event. use getCollectionDocsFromIds");
  return await col.where(firebase.firestore.FieldPath.documentId(), "in", docIds)
}

export async function getCollectionDocsFromIds(col, docIds) {
  var res = [];
  let ids = [...docIds];
  //log.log("ids", ids);
  while (ids.length > 0) {
    let someids = ids.splice(0, 10);
    //log.log("someids", someids);
    let docsqs = await col.where(firebase.firestore.FieldPath.documentId(), "in", someids).get();
    docsqs.forEach((doc) => { 
      res.push({ id:doc.id, ...doc.data() });
    });
  }
  //log.log("getCollectionDocsFromIds", res);
  return res;
}

export function whereDocIdStartWith(col, prefix) {
  //log.log("whereDocIdStartWith", prefix);
  return col
    .where(firebase.firestore.FieldPath.documentId(), '>=', prefix)
    .where(firebase.firestore.FieldPath.documentId(), '<=', prefix + '\uf8ff');
}

export async function deleteDocsInCollection(col, ids) {
  let batch = db.batch();
  ids.forEach((id) => {
    batch.delete(col.doc(id));
  });
  batch.commit();
}

export async function loadObj(docPath) {
  let doc = await docPath.get();
  return { id:doc.id, ...doc.data() };
}

export async function loadMap(coll) {
  let snapshot = await coll.get();
  return Object.fromEntries(snapshot.docs.map(doc => [doc.id, {id:doc.id, ...doc.data()}]));
}

export async function loadArr(coll) {
  let snapshot = await coll.get();
  return snapshot.docs.map(doc => { return {id:doc.id, ...doc.data()}});
}

import { mergeDeep } from "./utils";

// Careful about circular dependencies, does not check
export async function inheritedObj(col, id, init) {
  log.log("inheritedObj", id);
  let obj = init || await loadObj(col.doc(id));
  log.log("loadedObj", id, obj);
  if (!obj.template)
    return obj;
  let parent = await inheritedObj(col, obj.template);
  let merged = mergeDeep(parent, obj);
  log.log("merged", merged);
  return merged;
}

export async function bindInheritedObj(that, prop, col, id, fun) {
  that.$set(that, prop, fun(await inheritedObj(col, id)));
  fun = fun !== undefined ? fun : (id) => id; // call function or identity
  let listener = col.doc(id).onSnapshot(async (doc) => {
    let obj = { id:doc.id, ...doc.data() };
    log.log("bindInheritedObj doc=", obj);
    let res = fun(await inheritedObj(col, id, obj));
    log.log("bindInheritedObj res=", res);
    that.$set(that, prop, res);
  });
  return listener;
}